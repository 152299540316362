import gql from 'graphql-tag'


export const GET_STORIES = gql`
    query list($current: String!, $size: Int) {
        getStories(current: $current, pageSize: $size) { 
            partitionKey
            sortKey
            username
            date
            name
            path
            schemeId
            statusId
            token
            url
        }
    }
`

export const QUEUE_STORY = gql` 
    mutation queue { 
        queue
    }
`