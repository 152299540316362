import React, { useState } from 'react'

const endpoint = "https://api.mattjalvarez.com"

const post = async (path, body) => { 
  const response = await fetch(`${endpoint}/faible/${path}`, {
      method: 'post',
      body:   JSON.stringify(body),
      headers: { 
          'Content-Type': 'application/json', 
      },
  })

  return await response.json()
}


export default props => { 
  const [ email, setEmail ] = useState("")
  const [ step, setStep ] = useState(1)
  const [ loginCode, setLoginCode ] = useState("")
  const submit = async (event) => { 
    event.preventDefault()
    
    switch(step) { 
      case 1: // send email address
        const response = await post("login", { username: email})
        if(response.error) { 
          alert("An error has happpened logging in " + response.message)
        } 
        else { 
          setStep(2)
        }
        break
      case 2: 
      default: 
        const body = await post("verify", { username: email, loginCode })
        if(body.token) { 
          localStorage.setItem("faible-token", body.token)
          props.history.push("/stories")
        }        
        else { 
          alert("The login code was invalid, please try again")
          setStep(1)
        }
        




    }

  }

      

  return  <div className="d-flex justify-content-center" style={{height: "100%", background: 'white url("https://s3.amazonaws.com/faible/web/v1/static/login_back.jpg") no-repeat fixed center', backgroundSize: 'cover'}} >
            <div className="card bg-light mb-3 align-self-center">
              <div className="card-header">fAIble</div>
              <div className="card-body">
                <h4 className="card-title">Log In</h4>
                <div className="card-text">
                  <form onSubmit={submit}>
                    <fieldset>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input type="email" 
                                className="form-control"
                                placeholder="Enter email" 
                                onChange={ e => setEmail(e.target.value)}
                                value={email}
                                disabled={step > 1}
                                />
                        <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                      </div>
                      <div className="form-group" style={{display: step == 2 ? "" : "none"}}>
                        <label htmlFor="exampleInputEmail1">Login Code</label>
                        <input type="text" 
                                className="form-control" 
                                placeholder="ex: 123456" 
                                onChange={ e => setLoginCode(e.target.value)}
                                value={loginCode}
                                />
                        <small id="emailHelp" className="form-text text-muted">A code has been sent to {email}, enter it below to log in</small>
                      </div>
                      <button type="submit" className="btn btn-primary" disabled={ !(step == 1 && email) && !(step == 2 && loginCode)}>Submit</button>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>

}
     

