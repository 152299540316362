import React, { Fragment } from 'react'
import { Route, BrowserRouter as Router } from "react-router-dom"

import Login from './scenes/login'
import Home from './scenes/home'
import Stories from './scenes/stories'

export default () => 
                <Router>
                    <Fragment>
                        <Route path="/" exact component={Home} />
                        <Route path="/login" component={Login} />
                        <Route path="/stories" component={Stories} />
                    </Fragment>
                </Router>