import React from 'react'
import {  Link } from 'react-router-dom'

export default () => 
<div className="d-flex justify-content-center" style={{height: "100%", background: 'white url("https://s3.amazonaws.com/faible/web/v1/static/login_back.jpg") no-repeat fixed center', backgroundSize: 'cover'}} >
    <div className="card bg-light mb-3 align-self-center">
    <div className="card-header">
        <span className="float-left">fAIble II</span>
        <span className="float-right">
            <Link className="btn btn-outline-primary" to="/login">Log In</Link>
        </span>
    </div>
    <div className="card-body">
        <h4 className="card-title">Welcome to fAIble II, the second iteration of the fAIble narrative generation system</h4>
        <div className="card-text">
        Funded by the NSF
        </div>
    </div>
    </div>
</div>