import React, { useState } from 'react'
import {  Link } from 'react-router-dom'
import { GET_STORIES, QUEUE_STORY } from './queries'
import { Query, Mutation } from '../../helpers'

const fill = record => { 
    switch (record.statusId) { 
        case 1: 
            record.status = "Events"
            break
        case 2: 
            record.status = "Translation"
            break
        case 3: 
            record.status = "NLG"
            break
        case 4: 
            record.status = "Complete"
            break
        default: 
            record.status = "N/A"
            break
    }
    return record
}
const DownloadLink = ({record}) => { 
    if(record.statusId === 4) { 
        return <a href={record.url} target="_blank">View Story</a>
    }
    else { 
        return <strong>Story Not Yet Available</strong>
    }
}
const Story = ({record}) => 
    <tr>
        <td>{record.name}</td>
        <td>{record.token}</td>
        <td>{record.date}</td>
        <td>{record.status}</td>
        <td>
            <DownloadLink record={record}></DownloadLink>
        </td>
    </tr>

const Stories = ({records}) => 
    <table className="table table-striped table-hover">
        <thead>
            <tr>
                <th>Schema</th>
                <th>Token</th>
                <th>Date</th>
                <th>Status</th>
                <th>Download</th>
            </tr>
        </thead>
        <tbody>
            {records.map(record => <Story key={record.sortKey} record={record}></Story>)}
        </tbody>
    </table>
const Body = ({data, refetch, fetchMore}) => { 
    console.log("DATA", JSON.stringify(data, null, 4))
    const stories = data.getStories.map(i => fill(i))
    const [ loading, setLoading ] = useState(false)

    const refresh = async () => { 
        setLoading(true)
        await refetch()
        setLoading(false)
    }

    return  <div className="card-body pre-scrollable">
                <h4 className="card-title" style={{marginBottom: "2em"}}>
                    <span className="float-left">Your Stories</span>
                    <span className="float-right">
                        <button className="btn btn-outline-primary" onClick={refresh}>Refresh</button>
                    </span>
                </h4>
                
                <div className="card-text"> 
                    {loading ? "Loading...." : <Stories records={stories}></Stories>}
                </div>
            </div>
}

const QueueButton = ({ update }) => 
    <button type="button" className="btn btn-outline-primary"
        onClick={async e => { 
            const variables = {}
            try { 
                const response  = await update({ variables} )
                alert(response.data.queue)
               
                
            }
            catch (err) { 
                console.error("Issue Queueing", JSON.stringify(err, null, 4))
                alert("Error Queueing Record", JSON.stringify(err, null, 4))
            }
            
        }}>
       Queue
    </button>

export default () => 
<div className="d-flex justify-content-center" style={{height: "100%", 
                                                background: 'white url("https://s3.amazonaws.com/faible/web/v1/static/login_back.jpg") no-repeat fixed center', 
                                                backgroundSize: 'cover'}} >
        
    <div className="card bg-light mb-3 align-self-center col-sm-8 col-xs-12 mh-100" style={{paddingLeft: "0px", paddingRight: "0px"}}>
        <div className="card-header">
            <span className="float-left">fAIble</span>
            <span className="float-right">
                <Mutation mutation={QUEUE_STORY}>
                    <QueueButton ></QueueButton>
                </Mutation>
            </span>
        </div>
        <Query  
            query={GET_STORIES} 
            fetchPolicy="network-only" 
            variables={{ current: "0", size: 25 }} 
            >
                <Body></Body>    
        </Query> 
           
    </div>
</div>



