import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'

import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from 'react-apollo'
import * as serviceWorker from './serviceWorker'


const httpLink = createHttpLink({
    uri: "https://api.mattjalvarez.com/faible/graphql",
    fetchOptions: {
        method: "POST"
    }
})
  
const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            Authorization: `Bearer ${localStorage.getItem("faible-token")}`,
            'Accept': 'application/json',
            'Access-Control-Request-Headers': '*',
            'Access-Control-Request-Method': '*',
        }
    }
})


const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
})


const Root = () =>  <ApolloProvider client={client}>
                            <App />
                    </ApolloProvider>

ReactDOM.render(<Root></Root>, document.getElementById('root'))

serviceWorker.unregister();


